<template>
  <div class="RegistrationDiscount">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <div class="btn">
          <el-button type="primary" @click="handleExport1" plain
            >导出报名折扣表</el-button
          >
          <el-button
            type="primary"
            @click="handleExport2"
            plain
            style="margin-right: 10px"
            >导出报名折扣订单详细表
          </el-button>
          <el-upload
            ref="upload"
            :action="urls.importDiscountExcel"
            name="file"
            :headers="{
              Authorization: stores.state.token,
            }"
            :data="{ competitionId: this.competitionId, type: 1 }"
            :multiple="false"
            :show-file-list="false"
            :on-success="uploadFileSuccess"
            :on-error="uploadFileSuccess"
            list-type="文件类型列表"
            :disabled="allow"
          >
            <el-button
              style="height: 40px; margin-right: 10px"
              type="primary"
              plain
              >上传核对后表格</el-button
            >
          </el-upload>
        </div>
      </div>
      <div class="main-card">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="550"
          :header-cell-style="{ textAlign: 'center' }"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column label="文件名">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              <span>{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDownload(scope.$index, scope.row)"
                >下载</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import discount from "../api/discount";
import store from "../store";
export default {
  data() {
    return {
      competitionId: this.$route.params.id,
      tableData: [],
      urls: discount.URLs,
      stores: store,
      allow: true,
    };
  },
  mounted() {
    console.log(this.$refs.upload);
    let end = this.$route.params.endTime,
      odate1 = new Date(),
      odate2 = new Date(end);
    if (odate1 > odate2) {
      this.allow = false;
      console.log("进来了");
    }
  },
  created() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      try {
        const result = await this.$api.getDiscountList(
          `search_EQ_competitionId=${this.competitionId}&search_EQ_type=1`
        );
        this.tableData = result.pageResults;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    async handleExport1() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportDiscount(
          `competitionId=${this.competitionId}`
        );
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleExport2() {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      try {
        const result = await this.$api.exportDiscountDetail(
          `competitionId=${this.competitionId}`
        );
        console.log(result);
        let content = result.headers["content-disposition"].split(";"); // 从响应头中拿到文件名
        let fileName = content[1].split("=")[1]; // 从响应头中拿到文件名
        console.log(decodeURI(fileName), result);
        if (result.data) {
          let blob = new Blob([result.data], {
            type: "application/vnd.ms-excel",
          });
          let objectUrl = URL.createObjectURL(blob); //创建URL
          a.href = objectUrl;
          a.download = decodeURI(fileName);
          a.click();
          URL.revokeObjectURL(objectUrl); // 释放内存
          setTimeout(() => {
            document.body.removeChild(a);
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //上传组别时的回调处理
    uploadFileSuccess(callBackData) {
      const { status_code, msg } = callBackData;
      console.log(callBackData);
      if (status_code === "200") {
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.getTableData();
      } else if (status_code == "1002") {
        this.$message({
          type: "warning",
          message: "文件格式不正确",
        });
      }
    },
    handleDownload(index, row) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = row.url;
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
      }, 2000);
    },
  },
};
</script>

<style lang="less">
.RegistrationDiscount {
  .box-card {
    width: 100%;
    .clearfix {
      &:before,
      &:after {
        display: table;
        content: "";
      }
      &:after {
        clear: both;
      }
      .btn {
        display: flex;
        // float: right;
        .select {
          width: 100px;
          margin-right: 20px;
        }
        .whether {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    .main-card {
    }
    .page-card {
      margin: 30px 0;
      float: right;
    }

    .text {
      font-size: 14px;
    }

    .item {
      margin-bottom: 18px;
    }
  }
  .dialog-footer,
  .el-dialog__header {
    text-align: center;
  }
  .el-col-11 {
    width: 20%;
  }
  .el-col-2 {
    width: 1.3%;
  }
}
</style>
