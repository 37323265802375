<template>
  <div class="RefundRatio">
    <el-page-header
      @back="goBack"
      :content="nav"
      title=""
      style="margin-bottom:10px"
    >
    </el-page-header>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="报名折扣" name="1">
        <Bar1></Bar1>
      </el-tab-pane>
      <el-tab-pane label="门票折扣" name="2">
        <Bar2 v-if="tab.name == '2'"></Bar2>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Bar1 from '../../components/RegistrationDiscount.vue'
import Bar2 from '../../components/TicketDiscount.vue'
export default {
  data() {
    return {
      nav: this.$route.params.back,
      id:this.$route.params.id,
      activeName: '1',
      tab: {},
      name: '',
    }
  },
  components: {
    Bar1,
    Bar2,
  },
  mounted() {
    this.updateType()
  },
  methods: {
    updateType() {
      let type = this.$route.params.name
      //通过拿到的值不同，更改activeName的值
      if (type == '1') {
        this.tab.name = '1'
        this.activeName = '1'
      } else if (type == '2') {
        this.tab.name = '2'
        this.activeName = '2'
      }
    },
    handleClick(tab, event) {
      // console.log(tab, event)
      this.tab = tab
      if (tab.name == '1') {
        this.name = 1
      } else if (tab.name == '2') {
        this.name = 2
      }
      this.$router.push({
        name: 'Discount',
        params: { back: this.nav, id: this.id, name: this.name,endTime:this.$route.params.endTime },
      })
    },
    goBack() {
      this.$router.push({ name: 'Home' })
    },
  },
}
</script>

<style lang="less"></style>